/* バナー本体 */
.news-banner {
  display: block;
  width: 40%;
  height: 35px;
  overflow: hidden;
  background-color: rgb(233, 233, 233);
  margin: 15px;
}

/* バナー内コンテンツ */
.news-banner__content {
  display: inline-block;
  line-height: 10px;
  color: rgb(92, 92, 92);
  padding-left: 100%;
  white-space: nowrap;
  animation: animate-banner 20s linear infinite;
}

/* バナー内の要素をすべて選択 */
.news-banner__content > * {
  display: inline-block;
}

/* 横にスクロールさせるアニメーション */
@keyframes animate-banner {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}