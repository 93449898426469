.information-table-row-closeed{
  background-color: lightgray;
}

.information-table-cell{
  white-space : pre-wrap;
  word-wrap : break-word;
}

.information-table-cell-post{
  width: 200px;
}

.information-table-cell-title{
  width: 300px;
}

.important{
  font-size: 1.5em;
  font-weight: bolder;
  color: tomato;
  margin:0px 10px;
}