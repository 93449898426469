
@media screen and (max-width :640px){
    .MuiToolbar-root p{
        font-size: 10px ; 
    }
    .header_company{
      font-size: 10px;
      margin:5px;
    }
    
    .header_username{
      font-weight: bolder;
      font-size: 8px;
    }
    
  }