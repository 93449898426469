.form_content{
  margin:20px;
}
.form_content > h3{
  color: rgb(96, 99, 102);  
}

.form-label{
  color: rgb(96, 99, 102);
  font-weight:bold;
  padding-right: 10px;
}

.input-multiline{
  width:50%;  
}